<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' :subPath="title"></bread-crumb>
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="100px" class="form_body">
      <el-form-item label="标题" prop="title" style="width: 400px;">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="专辑" prop="category_id">
        <el-select v-model="formData.category_id" placeholder="请选择专辑" filterable>
          <el-option label="请选择专辑" :value="0"></el-option>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="pic">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handlePicSuccess">
            <img v-if="picUrl" :src="picUrl+'?imageView2/0/w/50'" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <!-- <el-form-item label="来源" prop="source" style="width: 400px;">
        <el-input v-model="formData.source"></el-input>
      </el-form-item> -->
      <el-form-item label="发布时间" prop="publish_start">
        <el-date-picker
          v-model="formData.publish_start"
          type="datetime"
          value-format="timestamp"
          placeholder="发布时间"></el-date-picker>
      </el-form-item>
      <el-form-item v-if="canCommentAdmin==1" label="是否评论" prop="can_comment" style="width: 400px;">
        <el-radio v-model="formData.can_comment" :label="0">关闭</el-radio>
        <el-radio v-model="formData.can_comment" :label="1">开启</el-radio>
        <el-radio v-model="formData.can_comment" :label="2">开启并通过</el-radio>
      </el-form-item>
      <el-form-item label="内容" prop="body" style="width: 98%;">
        <my-ueditor :value="formData.body" @getContent="getBody" style="line-height: 10px;"></my-ueditor>
      </el-form-item>
      <div style="text-align: center;">
        <el-button @click="closeContentPage" style="margin-right: 20px;">取 消</el-button>
        <el-button type="primary" @click="addConfirm">确 定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import MyUeditor from '../MyUeditor.vue'
export default {
  name: 'content_add2',
  components: {
    'bread-crumb': BreadCrumb,
    'my-ueditor': MyUeditor
  },
  mounted () {
    this.id = parseInt(this.$route.query.id)
    this.paramStr = this.$route.query.param
    if (this.id > 0) {
      this.title = '内容编辑'
    } else {
      this.title = '内容添加'
    }
    this.getCategoryList()
    if (this.id > 0) {
      this.getInfo(this.id)
    }
  },
  data () {
    return {
      id: 0,
      paramStr: '',
      mediaFileList: [],
      title: '',
      categoryList: [],
      canCommentAdmin: 1,
      formData: {
        title: '',
        category_id: 0,
        pic: '',
        source: '',
        body: '',
        can_comment: 0,
        publish_start: ''
      },
      picUrl: '',
      formRules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: '分类不能为空', trigger: 'blur' }
        ],
        // pic: [
        //   { required: true, message: '图片不能为空', trigger: 'blur' }
        // ],
        body: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeContentPage () {
      this.$refs.formRef.resetFields()
      this.mediaFileList = []
      this.picUrl = ''
      this.$router.push('/content/list?param=' + this.paramStr)
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.formData.publish_start = this.formData.publish_start / 1000
        if (this.id === 0) {
          this.addContentPost()
        } else {
          this.updateContentPost()
        }
      })
    },
    async getCategoryList () {
      const res = await this.$http.get('/admin/category/select_list')
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getInfo () {
      const res = await this.$http.get('/admin/content/info/' + this.id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          category_id: res.data.category_id,
          pic: res.data.pic,
          source: res.data.source,
          body: res.data.body,
          can_comment: res.data.can_comment,
          publish_start: res.data.publish_start * 1000
        }
        if (this.formData.publish_start === 0) {
          this.formData.publish_start = ''
        }
        this.canCommentAdmin = res.data.can_comment_admin
        this.picUrl = res.data.pic_url
        this.mediaFileList = []
      }
    },
    getBody (content) {
      this.formData.body = content
    },
    handlePicSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.picUrl = resp.data.url
        this.formData.pic = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    },
    async addContentPost () {
      const res = await this.$http.post('/admin/content/add', this.formData)
      if (res.code === 0) {
        this.closeContentPage()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateContentPost () {
      const res = await this.$http.post('/admin/content/update/' + this.id, this.formData)
      if (res.code === 0) {
        this.closeContentPage()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.form_body {
  background-color: #ffffff;
  padding: 20px 20px 30px 0px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.avatar {
  width: 50px;
  height: 50px;
  display: block;
}
</style>
